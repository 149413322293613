/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~normalize.css";

@import "fonts";
@import "fontello";
@import 'vars';

html {
  height: 100vh;
  width: 100vw;
}
body {
  ::-webkit-scrollbar, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-clip: padding-box;
    border: 2px solid transparent;
    
  }
  ::-webkit-scrollbar-track {
    background-color: $scroll-background;
    
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px $scroll-thumb;
  }

  height: 100vh;
  width: 100vw;
}
[appTableHeader] {
  transition: 150ms ease;
}
.header-shadowed {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.10);
}
.mat-progress-spinner {
  circle {
    stroke: $medium-grey;
  }
}
textarea::-webkit-scrollbar {
  width: 6px;
  border-radius: 2px;
  border: 1px solid transparent;
}
.cdk-overlay-pane {
  max-width: 90vw!important;
}
