.icon-memento-edit:before {
  content: '\e800';
}
.icon-memento-delete:before {
  content: '\e801';
}
.icon-memento-plus:before {
  content: '\e802';
}
.icon-memento-add_image:before {
  content: '\e804';
}
.icon-memento-cancel:before {
  content: '\e803';
}
.icon-memento-check:before {
  content: '\e805';
}
