$main-font: Roboto, sans-serif;

// todo current var colors should be changed to the new ones in time
$accent: #F32052;
$accent-deep: #C60A37;
$grey: #EBEDED;
$medium-light-grey: #C5C8C9;
$medium-grey: #9899A0;
$dark-grey: #3C474B;
$blue-deep: #14BFFB;
$blue-deep-lighter: #DCF6FF;
$blue-deep-darker: #00ADEA;
$blue_5: #F3FCFF;
$orange: #FF5300;
$light-orange: #FFDDCC;
$light-orange-darker: #ffcfb6;
$green: #00BD8F;
$light-grey-blue: #e8ecf3;
$light-grey: rgba(173, 178, 187, 0.5);

$scroll-background: #EEEFF0;
$scroll-thumb: #9899A0;

$light-red: #FBFBFB;

$black: rgba(60, 71, 75, 1);
$black_100: rgb(0, 0, 0);
$black_80: rgba(60, 71, 75, 0.8);
$black_30: rgba(60, 71, 75, 0.3);
$black_10: rgba(60, 71, 75, 0.1);
$black_5: rgba(60, 71, 75, 0.05);
$white: rgba(255, 255, 255, 1);

$accent: rgba(243, 32, 82, 1);
$accent-deep: rgba(198, 10, 55, 1);

$blue: rgba(20, 191, 251, 1);
$blue-deep: rgba(10, 181, 241, 1);
$blue_15: rgba(20, 191, 251, 0.15);
$blue_20: rgba(20, 191, 251, 0.2);
$blue_30: rgba(20, 191, 251, 0.3);
$blue_50: rgba(20, 191, 251, 0.5);
$blue_5: rgba(20, 191, 251, 0.05);

$gray-shadow_04: rgba(0, 0, 0, 0.04);
$gray-shadow_10: rgba(0, 0, 0, 0.10);
$gray-shadow_15: rgba(0, 0, 0, 0.15);
$blue-gray: #F4F6FA;
$blue-gray-text: #606873;
$table-text-color: #62646B;
$table-header-color: #9899A0;

$orange: rgba(255, 83, 0, 1);
$orange_10: rgba(255, 83, 0, 0.1);

$green: rgba(0, 189, 143, 1);
$laptop-rez: 1440px;

$error-color: #FF5300;

$input-brown: #D6D8DD;

@mixin laptop {
  @media screen and (max-width: 1440px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 1279px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin tabs {
  display: flex;
  height: 100%;
  &-tab {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: 150ms ease-out;
    padding: 0 64px;
    position: relative;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    color: $black_80;

    &:after {
      content: '';
      transition: 200ms ease-out;
      height: 2px;
      background: $accent;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      width: 100%;
    }
    &--active {
      color: $accent;
      &:after {
        transform: scaleX(1);
      }
    }
    &.active {
      color: $accent;
      &:after {
        transform: scaleX(1);
      }
    }
  }
}
@mixin page {
  display: grid;
  grid-template-rows: 64px calc(92% - 64px) 64px;
  height: 100%;
  margin: 0 24px;
  .page-header {
    width: 100%;
    max-width: 1440px;
    height: 64px;
  }
  .page-card {
    background: $white;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    grid-template-rows: 64px auto;
    box-shadow: 0 4px 10px $black_10;
    height: 100%;
    overflow: auto;

    &-header {
      text-transform: uppercase;
      box-shadow: 0 4px 4px $black_10;
      align-items: center;
      position: sticky;
      top: 0;
      background: $white;
      z-index: 99;
      &--flex {
        display: flex;
      }
      &-tabs {
        @include tabs;
      }
      &-title {
        padding: 0 24px;
      }
      &-actions {
        margin-left: auto;
        padding: 0 24px;
      }
    }
    &-body {
      height: 100%;
      max-height: 100%;
    }
  }
}
@mixin transition-150 {
  transition: 150ms ease;
}
@mixin box-shadow-card {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
@mixin box-shadow-modal {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}
@mixin button($bc-color, $bc-hover, $text-color, $hover-text-color) {
  padding: 7px 20px;
  font-size: 14px;
  border: none;
  background-color: $bc-color;
  color: $text-color;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  text-align: center;
  max-height: 30px;
  transition: 150ms ease;
  &:not(:last-child) {
    margin: 0 10px;
  }
  &:hover {
    background-color: $bc-hover;
    color: $hover-text-color;
  }
  &:disabled {
    background: none;
    opacity: 0.7;
    cursor: default;
    color: $dark-grey;
  }
}
@mixin icon-button($bg: none, $bg-hover: $black_10, $fill: $black_30, $fill-hover: $black_30) {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background: $bg;
  padding: 0;
  min-width: 32px;
  min-height: 32px;
  transition: 250ms ease-out;
  cursor: pointer;
  border: none;
  outline: none;
  &:hover,
  &.active {
    background: $bg-hover;
    path {
      fill: $fill-hover;
    }
  }
  svg {
    width: 16px;
  }
  path {
    transition: 250ms ease-out;
    fill: $fill;
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    fill: $black_10;
    ::ng-deep svg {
      path {
        fill: $black_10;
      }
    }
  }
}
@mixin modal {
  display: grid;
  grid-template-rows: 50px auto 50px;
  .modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: solid 0.5px $black_30;

    .close {
      @include icon-button;
    }
  }
  .modal-content {
    padding: 24px;
  }
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 24px;
  }
}
@mixin round-button($bg-color, $color, $bg-color-hover, $color-hover) {
  background-color: $bg-color;
  color: $color;
  cursor: pointer;
  transition: 150ms ease-out;
  text-transform: uppercase;
  height: 48px;
  box-sizing: border-box;
  padding: 0 24px 0 16px;
  font-weight: 300;
  border-radius: 48px;
  user-select: none;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  &--no-icon {
    padding: 0 16px;
  }
  svg {
    margin-right: 8px;
  }
  path {
    fill: $color;
    transition: fill 150ms ease-out;
  }
  &:hover {
    background-color: $bg-color-hover;
    color: $color-hover;
    path {
      fill: $color-hover;
    }
  }
  &:disabled,
  &--disbled {
    background-color: $black_10;
    pointer-events: none;
  }
}
@mixin clickable-element {
  cursor: pointer;
  user-select: none;
  // hide all highlight by tap
  -webkit-tap-highlight-color: transparent;
  // hide all outlines by tap and click
  outline: none;
}

@mixin header-actions-btn($bg-color, $color, $bg-color-hover, $color-hover) {
  @include clickable-element;
  display: flex;
  align-items: center;
  border-radius: 40px;
  border: none;
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  color: $color; //$blue;
  height: 48px;
  padding: 0 20px;
  text-transform: uppercase;
  transition: 0.15s ease;
  background-color: $bg-color; //$blue_15;

  i {
    font-size: 18px;
    margin-right: 10px;
  }
  &:disabled {
    background: $grey !important;
    color: $black_30 !important;
    cursor: default;
  }
  &:hover {
    background-color: $bg-color-hover; //$blue;
    color: $color-hover; //$white;
  }
}
