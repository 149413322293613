@font-face {
  font-family: 'Roboto';
  src: url("../assets/3966.ttf");
}

@font-face {
  font-family: 'memento-icons';
  src: url('../assets/fonts/fontello/memento-icons.eot?71326860');
  src: url('../assets/fonts/fontello/memento-icons.eot?71326860#iefix') format("embedded-opentype"),
  url('../assets/fonts/fontello/memento-icons.woff2?71326860') format("woff2"),
  url('../assets/fonts/fontello/memento-icons.woff?71326860') format("woff"),
  url('../assets/fonts/fontello/memento-icons.ttf?71326860') format("truetype"),
  url('../assets/fonts/fontello/memento-icons.svg?71326860#memento-icons') format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-memento-"]:before,
[class*=" icon-memento-"]:before {
  font-family: "memento-icons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
